import React from "react"

import Layout from '../components/layout'
import Head from '../components/head'
import QbsTabbed from "../components/qbs_tabbed"
import { useStaticQuery } from "gatsby"

const Qbs = () => {

    const data = useStaticQuery(graphql`
        query {
            allQbRanksCsv {
                nodes {
                    qb
                    headshot_url
                }
            }
        }      
    `)

    const markupData = data.allQbRanksCsv.nodes.map(node => {
        return (
            {
                "@context": "http://schema.org",
                "@type": "Person",
                "givenName" : node.qb,
                "image" : node.headshot_url,
                "worksFor" : {
                    "@context": "http://schema.org",
                    "@type": "Organization",
                    "name" : "National Football League",
                    "sameAs" : "https://www.nfl.com"  
                }
            }
        )
    }) 

    return (
        <Layout>
            <Head
                title="QB Rankings "
                pageDescription="Data driven rankings of NFL QBs"
                schemaMarkup={markupData}    
            />
            <QbsTabbed
                activeIndex={0}
            />
        </Layout>
    )
}

export default Qbs

